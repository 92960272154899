<template>
  <div class="serviceSheetForm-container">
    <form-panel ref="form" :form="form" :queryUrl="queryUrl" @update="update">
      <col2-block title="订单流转">
        <!-- 进度 -->
        <col2-progress :status="progressList" :percent="progress" />
      </col2-block>
      <col2-block title="订单信息">
        <el-row>
          <col2-item label="订单编号" :text="orderCode" />
          <col2-item
            v-if="laborFeeShow"
            label="是否房产"
            :text="`${info.countersignType === 2 ? '是' : '否'}`"
          />
        </el-row>
        <col2-item label="订购服务" :text="info.subName" />
        <col2-item label="用户姓名" :text="info.userName" />
        <col2-item
          label="工单来源"
          :text="
            `${info.sourceType ? sourceTypeMap[info.sourceType] : ''}${
              info.serialNumberOutside
                ? '(' + info.serialNumberOutside + ')'
                : ''
            }${
              info.sourceType == 1 || info.sourceType == 2
                ? '(' + info.inuserName + '  ' + info.inuserMobileNum + ')'
                : ''
            }`
          "
        />
        <col2-item label="联系电话" :text="info.userPhone" />
        <col2-item label="下单时间" :text="info.referTime" />
        <col2-item label="用户地址" :text="info.userAddress" />
        <col2-item label="预约时间" :text="`${info.serverTime || '无'}`" />
        <col2-item label="服务商家" :text="info.shopName" />
        <col2-item label="订单状态">
          <span style="color:#a94442"
            >{{ orderStatusMap[info.orderStatus]
            }}{{
              info.orderStatus == "5" ? `(${closeType[info.closeType]})` : ""
            }}{{
              info.isAftersalesIng === 1 && info.orderStatus !== "5"
                ? "(售后中)"
                : ""
            }}</span
          >
        </col2-item>
        <col2-item
          label="物流金额"
          :text="`${info.expensesMoney || '0.00'}元`"
        />
        <col2-item
          label="支付方式"
          :text="
            `${payTypeMap[info.payType] || ''}${
              info.payNo ? '(' + info.payNo + ')' : ''
            }`
          "
        />
        <col2-item
          label="订单类型"
          :text="
            `${
              info.payCategory === 0
                ? '个人订单'
                : '组织订单(' + info.orgName + ')'
            }`
          "
        />
        <col2-item
          label="订单金额"
          :text="
            `${info.totalMoney || ''}元${
              info.subName && info.subName.indexOf('维修') !== -1
                ? '(人工费' + info.laborFee + ',材料费' + info.materialFee + ')'
                : ''
            }`
          "
        />
        <col2-item
          label="支付状态"
          :text="`${info.payStatus === '0' ? '未支付' : '已支付'}`"
        />
        <col2-item label="支付时间" :text="info.onlinePaidTime" />
        <col2-item
          v-if="info.payStatus !== '0'"
          label="应付金额"
          :text="`${info.orderMoney || ''}元`"
        />
        <col2-item
          v-if="info.payStatus !== '0'"
          label="实付金额"
          :text="`${info.realMoney || ''}元`"
        />
        <!-- <col2-item  label="抵价票券" :text="info.useTicketInfo" /> -->
        <col2-item
          label="开票信息"
          :text="`${info.invoicStatus === 2 ? '已开票' : '未开票'}`"
        />
      </col2-block>
      <col2-block title="收货人信息">
        <col2-item label="收货人姓名" :text="consignee.userName" />
        <col2-item label="收货人电话" :text="consignee.userPhone" />
        <col2-item label="收货人地址" :text="consignee.userAddress" />
      </col2-block>
      <col2-block title="订单内容">
        <!-- 订单内容列表 begin -->
        <table-panel
          v-if="content.products && content.products.length > 0"
          :headers="orderHeaders"
          :tableData="content.products"
        >
          <template v-if="canEdit" #operateSlot="scope">
            <div
              v-if="
                (info.orderStatus == 2 || info.orderStatus == 6) &&
                  info.deliveryType == 1
              "
            >
              <div v-if="scope.row.expFirmCode">
                <v-button
                  text="编辑物流"
                  type="text"
                  @click="showExpress(scope.row, 'edit')"
                ></v-button>
                <v-button
                  text="删除物流"
                  type="text"
                  @click="delExpress(scope.row)"
                ></v-button>
              </div>
              <v-button
                v-else
                text="新增物流"
                type="text"
                @click="showExpress(scope.row)"
              ></v-button>
            </div>
          </template>
        </table-panel>
        <!-- 订单内容列表 end -->
        <col2-item :span="24" label="订单图片">
          <v-preview :imgs="picturesList"></v-preview>
        </col2-item>
        <col2-item :span="24" label="订单描述" :text="content.remark" />
      </col2-block>
      <col2-block v-if="newProgress.time" title="最新订单进度">
        <div class="newProgress">
          <span>{{ newProgress.time }}</span>
          <span>{{ newProgress.user }} </span>
          <span>{{ newProgress.remark }}</span>
          <a @click="showProgressDetail">>>点击查看详情</a>
        </div>
      </col2-block>
      <col2-block title="处理情况">
        <div></div>
        <el-form-item v-if="info.orderStatus == 2" label="处理后图片">
          <v-uploader
            ref="myUpload"
            :action="uploadUrl"
            :imgUrls.sync="form.imageUrls"
            :limit="4"
          >
          </v-uploader>
        </el-form-item>
        <el-form-item v-if="afterHanderPic" label="处理后图片">
          <v-preview :imgs="afterPicturesList"></v-preview>
        </el-form-item>
        <el-form-item
          label="工单加签"
          v-if="countersignTypeVisible"
          prop="countersignTypeList"
          :rules="[
            {
              required: Number(info.orderStatus) === 2,
              message: '请选择标签',
              trigger: 'change'
            }
          ]"
        >
          <!-- <v-select v-if="info.isSignType == 1" v-model="form.countersignType" :options="countersignTypeOps"></v-select> -->
          <chosenListPanel
            :list.sync="form.countersignTypeList"
            @select="toSelectCountersignType"
          ></chosenListPanel>
        </el-form-item>
        <el-form-item label="处理反馈" v-if="operationShow">
          <v-textarea v-model="form.operationDes" :maxlength="300"></v-textarea>
        </el-form-item>
        <el-form-item v-if="info.orderStatus == 2" label="处理状态">
          <v-select v-model="form.operaType" :options="operaOps"></v-select>
        </el-form-item>
        <div v-if="form.operaType !== 'close'">
          <el-form-item
            v-if="laborFeeShow"
            label="人工费"
            prop="laborFee"
            :rules="[
              {
                required: !form.materialFee && form.materialFee !== 0,
                message: '请输入人工费',
                trigger: 'blur'
              }
            ]"
          >
            <v-input-number :precision="2" v-model="form.laborFee" />
          </el-form-item>
          <el-form-item
            v-if="laborFeeShow"
            label="材料费"
            prop="materialFee"
            :rules="[
              {
                required: !form.laborFee && form.laborFee !== 0,
                message: '请输入材料费',
                trigger: 'blur'
              }
            ]"
          >
            <v-input-number :precision="2" v-model="form.materialFee" />
          </el-form-item>
          <el-form-item v-if="couponValueShow" label="抵价票券">
            <v-input-number
              v-model="form.couponNum"
            />&nbsp;&nbsp;张,&nbsp;总抵扣&nbsp;&nbsp;
            <v-input-number
              :precision="2"
              v-model="form.couponValue"
            />&nbsp;&nbsp;元
          </el-form-item>
          <el-form-item
            v-if="couponValueShow"
            label="实收金额"
            :rules="[
              { required: true, message: '请输入实收金额', trigger: 'blur' }
            ]"
          >
            <v-input-number
              v-if="laborFeeShow"
              :precision="2"
              disabled
              :value="cash"
            />
            <v-input-number v-else :precision="2" v-model="form.cash" />
          </el-form-item>
        </div>
      </col2-block>
      <col2-block title="用户评价" v-if="evaluateShow && evaluateList[0]">
        <div
          class="evaluate-panel"
          v-for="(item, index) in evaluateList"
          :key="index"
        >
          <div class="title">用户评价#{{ index + 1 }}</div>
          <div class="rate-row">
            <div class="col2">
              <span class="rate-label">服务质量</span>
              <rate
                :value="Number(item.quality)"
                disabled
                text-color="#ff9900"
                class="rate"
              >
              </rate>
            </div>
            <div class="col2">
              <span class="rate-label">服务速度</span>
              <rate
                :value="Number(item.speed)"
                disabled
                text-color="#ff9900"
                class="rate"
              >
              </rate>
            </div>
          </div>
          <div class="eva-cell" v-if="item.content">
            <span class="label">评价内容:</span
            ><span class="text">{{ item.content }}</span>
          </div>
          <div class="eva-cell" v-if="item.appraiseTime">
            <span class="label">评价时间:</span
            ><span class="text">{{ item.appraiseTime }}</span>
          </div>
          <div class="eva-cell" v-if="item.replyTime">
            <span class="label">回复时间:</span
            ><span class="text">{{ item.replyTime }}</span>
          </div>
        </div>
      </col2-block>
      <div v-if="canEdit" slot="footerSlot" class="footerBtn">
        <v-button
          v-show="submitTextBack"
          type="success"
          :text="submitTextBack"
          @click="submitForm(0)"
        ></v-button>
        <v-button
          v-show="submitTextNext"
          type="default"
          :text="submitTextNext"
          @click="submitForm(1)"
        ></v-button>
        <!-- 仓前要求新增功能 -->
        <v-button
          v-if="info.orderStatus === '2'"
          text="工单上报"
          permission="report"
          @click="onReport"
        ></v-button>

        <v-button
          v-if="redeployShow"
          permission="transfer"
          @click="showRedeploy"
          text="转派"
        ></v-button>
        <v-button
          v-if="returnVisitShow"
          type="success"
          text="进行回访"
          @click="showReturnVisit"
        ></v-button>
      </div>
    </form-panel>
    <multi-select
      :isShow.sync="isShow"
      :searchUrl="getCountersignListUrl"
      :headers="countersignTableHeader"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :responseKey="responseKey"
      :backFill.sync="form.countersignTypeList"
    >
      <template #searchSlot>
        <v-input label="标签名称" v-model="searchParams.labelName" />
        <v-select
          label="二级分类"
          v-model="searchParams.categoryId"
          :options="secondCategoryOps"
        />
      </template>
    </multi-select>
    <el-dialog title="订单进度" :visible.sync="dialogProgress" width="500px">
      <div>
        <el-timeline>
          <el-timeline-item
            class="pro-group"
            v-for="(item, index) in progressDetail"
            :key="index"
          >
            <div class="progress">
              <div class="progress-top">
                <span class="left">{{ item.track }}</span>
                <span class="right">{{ item.time }}</span>
              </div>
              <div class="progress-remark">{{ item.remark }}</div>
              <div class="progress-view" v-if="item.images.length > 0">
                <v-preview
                  :imgs="item.images"
                  :imgStyle="previewStyle"
                ></v-preview>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
    <el-dialog
      title="添加回访记录"
      :visible.sync="dialogRetuenVisit"
      width="600px"
    >
      <div>
        <el-form label-width="120px" label-suffix="：">
          <el-form-item label="方式">
            <checkbox-plus
              ref="returnType"
              v-model="returnForm.type"
              :options="returnType"
            />
          </el-form-item>
          <el-form-item label="服务质量">
            <rate
              class="rates"
              v-model="returnForm.quality"
              text-color="#ff9900"
            />
          </el-form-item>
          <el-form-item label="服务速度">
            <rate
              class="rates"
              v-model="returnForm.speed"
              text-color="#ff9900"
            />
          </el-form-item>
          <el-form-item label-width="0">
            <v-textarea
              v-model="returnForm.content"
              placeholder="请填写回访结果"
              :maxlength="100"
            ></v-textarea>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <v-button @click="submitReturnVisit" text="提 交"></v-button>
      </div>
    </el-dialog>
    <!-- 物流 begin -->
    <el-dialog
      title="物流信息"
      class="dialog"
      width="400px"
      :visible.sync="isExpressShow"
    >
      <el-form label-width="140px" label-suffix="：">
        <el-form-item label="快递单号">
          <v-input v-model="express.expNum"></v-input>
        </el-form-item>
        <el-form-item label="快递公司">
          <v-select2
            ref="expCode"
            v-model="express.expCode"
            v-bind="expCodeParams"
            @onChange="expChange"
          ></v-select2>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <v-button
          type="default"
          text="取 消"
          @click="isExpressShow = false"
        ></v-button>
        <v-button @click="addExpress" text="确 定"></v-button>
      </div>
    </el-dialog>
    <el-dialog
      title="物流详情"
      class="dialog"
      width="600px"
      :visible.sync="isExpressInfoShow"
    >
      <div v-for="(item, index) in expressInfoList" :key="index" class="expBox">
        <span>{{ item.ftime }}</span>
        <span>{{ item.context }}</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <v-button
          type="default"
          text="关 闭"
          @click="isExpressInfoShow = false"
        ></v-button>
      </div>
    </el-dialog>
    <!-- 物流 end -->
    <el-dialog
      title="转派"
      class="dialog"
      width="600px"
      :visible.sync="isRedeployShow"
    >
      <div>
        <el-input v-model="resData.userName" placeholder="请输入关键字">
          <el-button
            @click="search"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <ul 
          v-infinite-scroll="load"
          class="redeployList">
          <li v-for="(item, index) in redeployList" :key="index">
            <el-radio v-model="radio" :label="item">{{item.adminName}}</el-radio>
            <span class="number">{{ item.taskNum }}</span>
            <span>个工单在处理</span>
          </li>
        </ul>
        <div class="submit">
          <v-button @click="redeploy" text="确认分配"></v-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDetailURL,
  saveURL,
  addCallbackInfoURL,
  getPayTypeListURL,
  queryTaskListURL,
  uploadURL,
  getOrderLabelListURL,
  getExpInfoURL,
  addExpressInfoURL,
  getViewOrderDetailInfoURL,
  getExpressDetailsURL,
  delExpURL,
  querySecondCategoryURL,
  queryLabelConfigURL,
  querySignTypeURL,
  queryServiceIDURL,
  getDistributeList,
  orderRedeployOther,
  orderRedeploy,
  orderReportURL
} from "./api";
import {
  Col2Block,
  Col2Progress,
  Col2Item,
  TablePanel,
  MultiSelect,
  ChosenListPanel,
  CheckboxPlus
} from "@/components/bussiness";
import { vPreview, vUploader } from "components/control";
import { Rate, Row, Timeline, TimelineItem } from "element-ui";
import {
  operaOps,
  submitTextBackMap,
  submitTextNextMap,
  orderStatusMap
} from "./map";
import { generateMapByOpts } from "common/utils";
import _ from "lodash"; // eslint-disable-line
export default {
  components: {
    Col2Block,
    Col2Progress,
    Col2Item,
    TablePanel,
    vPreview,
    vUploader,
    Rate,
    elRow: Row,
    MultiSelect,
    ChosenListPanel,
    elTimeline: Timeline,
    elTimelineItem: TimelineItem,
    CheckboxPlus
  },
  data() {
    return {
      redeployList: [],
      radio: "",
      sourceTypeMap: {},
      orderStatusMap,
      operaOps,
      isRedeployShow: false,
      uploadUrl: uploadURL,
      id: this.$route.query.id,
      orderCode: this.$route.query.orderCode,
      queryUrl: getDetailURL,
      closeType: [
        "",
        "",
        "用户取消订单",
        "工作人员关单",
        "外赠超时关单",
        "售后完成退款"
      ],
      imgUrls: [],
      countersignTypeOps: [],
      canEdit: true,
      form: {
        operationDes: "您的订单已接收，稍后将安排工作人员为您服务。",
        caseId: this.$route.query.id,
        orderId: this.$route.query.id, // 新接口 订单字段
        operaType: "done",
        cash: undefined,
        laborFee: undefined,
        materialFee: undefined,
        couponNum: undefined,
        couponValue: undefined,
        orderType: 0,
        orderStatus: undefined,
        evaluate: "",
        content1: undefined,
        content2: undefined,
        content3: undefined,
        content4: undefined,
        imageUrls: [],
        dataObject: this.$route.query.orderCode,
        taskMemo: "",
        countersignType: undefined,
        countersignTypeList: []
      },
      consignee: {},
      content: {},
      dealInfo: {},
      evaluateList: {},
      grantDetail: {},
      info: {},
      operation: {},
      orderNumber: "",
      subcode: "",
      payTypeOps: [],
      payTypeMap: {},
      getCountersignListUrl: getOrderLabelListURL,
      countersignTableHeader: [
        {
          prop: "labelName",
          label: "标签名称"
        },
        {
          prop: "category",
          label: "分类"
        }
      ],
      isShow: false,
      searchParams: {},
      responseParams: {
        id: "id",
        name: "labelName"
      },
      responseKey: {
        id: "id",
        name: "text"
      },
      dialogProgress: false,
      progressDetail: [],
      previewStyle: {
        width: "60px",
        height: "60px",
        marginRight: "10px"
      },
      dialogRetuenVisit: false,
      returnType: [
        {
          label: "面访",
          value: 1
        },
        {
          label: "电话",
          value: 2
        }
      ],
      returnForm: {
        quality: 5,
        speed: 5,
        content: "",
        type: 1
      },
      header: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
      },
      // 订单内容 table begin
      expCodeParams: {
        searchUrl: getExpInfoURL,
        request: {
          text: "expFirm",
          value: "expCode"
        },
        response: {
          text: "expFirm",
          value: "expCode"
        }
      },
      express: {
        orderType: 2,
        orderId: "",
        orderDetailId: "",
        expNum: "",
        expCode: "",
        expFirm: ""
      },
      orderHeaders: [
        {
          prop: "productName",
          label: "商品名称"
        },
        {
          prop: "price",
          label: "单价"
        },
        {
          prop: "unit",
          label: "单位"
        },
        {
          prop: "count",
          label: "购买数量"
        },
        {
          prop: "shopName",
          label: "门店"
        },
        {
          prop: "serviceStatus",
          label: "售后状态"
        },
        {
          prop: "expInfo",
          label: "物流信息"
        },
        {
          prop: "expMarkInfo",
          label: "物流动态"
        }
      ],
      isExpressShow: false,
      isExpressInfoShow: false,
      expressInfoList: [],
      // 订单内容 table end
      secondCategoryList: [],
      secondCategoryOps: [],
      isCountersignType: true,
      resData: {
        curPage: 1,
        pageSize: 20,
        hasMore: true,
        isResIng: false,
        userName: ""
      },
      busy: true,
    };
  },
  async created() {
    await this.$store.dispatch("mapStore/queryOrderSource");
    const { orderSourceMap } = this.$store.getters["mapStore/getOrderSource"]();
    this.sourceTypeMap = orderSourceMap;
  },
  computed: {
    progressList() {
      let ops = [
        {
          name: "下单时间",
          time: this.info.referTime
        },
        {
          name: "接单时间",
          time: this.operation.inceptTime
        },
        {
          name: "开始处理",
          time: this.operation.startDealTime
        },
        {
          name: "处理完成 ",
          time: this.operation.endDealTime
        },
        {
          name: this.info.orderStatus === "5" ? "已关闭" : "已完成"
        }
      ];
      return ops;
    },
    progress() {
      const progressMap = {
        "1": 20,
        "6": 40,
        "2": 60,
        "3": 80,
        "4": 100,
        "5": 0
      };
      return this.info.orderStatus ? progressMap[this.info.orderStatus] : 0;
    },
    picturesList() {
      if (this.content.pictures && this.content.pictures.length > 0) {
        return this.content.pictures.map(img => img.url);
      } else {
        return [];
      }
    },
    afterPicturesList() {
      if (this.dealInfo.pictures && this.dealInfo.pictures.length > 0) {
        return this.dealInfo.pictures.map(img => img.url);
      } else {
        return [];
      }
    },
    newProgress() {
      if (this.operation) {
        return {
          time: this.operation.dealTime,
          user: this.operation.dealerName,
          remark: this.operation.dealText
        };
      } else {
        return {};
      }
    },
    submitTextBack() {
      return submitTextBackMap[this.info.orderStatus];
    },
    submitTextNext() {
      return submitTextNextMap[this.info.orderStatus];
    },
    countersignTypeVisible() {
      // 只能待处理、处理中的工单能够加签，且已加签的工单不能再次加签
      return (
        (Number(this.info.orderStatus) === 6 ||
          Number(this.info.orderStatus) === 2) &&
        this.info &&
        this.info.countersignTypeList &&
        this.info.countersignTypeList.length < 1 &&
        this.isCountersignType
      );
    },
    laborFeeShow() {
      let result =
        this.info &&
        this.info.orderStatus == 2 &&
        this.info.subName.indexOf("维修") != -1; // eslint-disable-line
      return result;
    },
    couponValueShow() {
      let result =
        this.info &&
        this.info.payType == 0 &&
        this.subcode != 100 &&
        this.subcode != 106 &&
        this.subcode != 829 &&
        this.info.orderStatus == 2; // eslint-disable-line
      return result;
    },
    operationShow() {
      const numMap = [1, 2, 6];
      return numMap.indexOf(Number(this.info.orderStatus)) > -1;
    },
    afterHanderPic() {
      const numMap = [3, 4, 5];
      return (
        numMap.indexOf(Number(this.info.orderStatus)) > -1 &&
        this.dealInfo &&
        this.dealInfo.pictures &&
        this.dealInfo.pictures.length > 0
      );
    },
    evaluateShow() {
      const numMap = [4, 5];
      return numMap.indexOf(Number(this.info.orderStatus)) > -1;
    },
    returnVisitShow() {
      let result =
        (this.info.orderStatus == 3 || this.info.orderStatus == 4) &&
        this.info.isReturnVisit == 1 &&
        this.info.userAddress &&
        this.$store.getters.getPermission("returnVisit"); // eslint-disable-line
      return result;
    },
    // 转派工单 按钮显示控制
    redeployShow() {
      let orderStatus = Number(this.info.orderStatus);
      let userInfo = this.$store.getters.getUserInfo;

      console.log('inceptUser', this.operation.inceptUser)
      console.log('orderStatus', orderStatus)
      console.log('userInfo', userInfo)
      let result =
        orderStatus === 1 ||
        ( (orderStatus === 2 || orderStatus === 6) && userInfo.id === this.operation.inceptUser)
      console.log(result)
      return result;
    },
    cash() {
      return (
        (this.form.laborFee || 0) +
        (this.form.materialFee || 0) -
        (this.form.couponValue || 0)
      );
    }
  },
  mounted() {
    this.$setBreadcrumb("工单详情");
    this.id && this.$refs.form.getData({ orderId: this.id, orderType: 0 });
    this.getPayTypeOps();
    this.searchParams.communityId = this.$route.query.communityId;
    const { noEdit } = this.$route.query;
    if (noEdit !== undefined) {
      // 代表不可编辑
      this.canEdit = false;
    }
  },
  methods: {
    getPayTypeOps() {
      this.$axios.get(getPayTypeListURL).then(res => {
        if (res.status === 100) {
          const { data } = res;
          let payTypeOps = data.map(item => {
            return {
              text: item.payTypeDesc,
              value: item.payType
            };
          });
          this.payTypeOps = payTypeOps;
          this.payTypeMap = generateMapByOpts(payTypeOps);
        }
      });
    },
    update(data) {
      let {
        consignee,
        content,
        dealInfo,
        evaluateList,
        grantDetail,
        info,
        operation,
        orderNumber,
        subcode
      } = data;
      content.products &&
        (content.products = this.handleContent(content.products));
      this.consignee = consignee;
      this.content = content;
      this.dealInfo = dealInfo;
      this.evaluateList = evaluateList;
      this.grantDetail = grantDetail;
      this.info = info;
      this.operation = operation;
      this.orderNumber = orderNumber;
      this.subcode = subcode;
      this.form.orderStatus = info.orderStatus;
      if (info.orderStatus === "1") {
        this.form.operationDes = "您的订单已接收，稍后将安排工作人员为您服务。";
      } else if (info.orderStatus === "6") {
        this.form.operationDes = "您的订单已开始处理，请关注。";
      } else if (info.orderStatus === "2") {
        this.form.operationDes =
          "服务已完毕，请为本次服务打分/评价，祝生活幸福!";
      }
      // 查询二级分类
      this.querySecondCategory(data.subcode);
      this.searchParams.subCode = subcode;
      this.searchParams.orderNumber = orderNumber;
      this.queryLabelConfig(
        subcode,
        this.$route.query.communityId,
        orderNumber
      );
      this.querySecondClassify();
    },
    toSelectCountersignType() {
      this.isShow = true;
    },
    submitForm(type) {
      let valid = this.$refs.form.validate();
      if (!valid) {
        return;
      }
      let data = this.handleParams();
      let saveUrl = "";
      if (this.info.orderStatus === "1") {
        saveUrl = saveURL.reviceive;
      } else if (this.info.orderStatus === "6") {
        saveUrl = saveURL.dispose;
      } else if (this.info.orderStatus === "2") {
        saveUrl =
          this.form.operaType !== "close" ? saveURL.finish : saveURL.close;
      }
      this.$axios
        .put(saveUrl, this.$qs.stringify(data), this.header)
        .then(res => {
          if (res.status === 100) {
            this.$message({
              type: "success",
              message: "保存成功",
              center: true
            });
            if (type === 0) {
              this.$router.go(-1);
            } else {
              this.$refs.form.getData({ orderId: this.id, orderType: 0 });
            }
          }
        });
    },
    handleParams() {
      let data = {};
      let keyArr = Object.keys(this.form);
      keyArr.forEach(value => {
        if (this.form[value] !== undefined) {
          data[value] = this.form[value];
        }
      });
      this.form.imageUrls.length > 1 &&
        this.form.imageUrls.forEach((item, index) => {
          data[`content${index + 1}`] = item;
        });
      data.imageUrls = JSON.stringify(this.form.imageUrls);
      if (this.countersignTypeVisible) {
        data.countersignType = JSON.stringify(
          this.form.countersignTypeList.map(item => item.id)
        );
      }
      if (this.cash && this.laborFeeShow) {
        data.cash = Number(this.cash) < 0 ? 0 : this.cash;
      }
      data.taskMemo = data.operationDes;
      return data;
    },
    showProgressDetail() {
      this.$axios
        .get(queryTaskListURL, {
          params: {
            caseId: this.id
          }
        })
        .then(res => {
          if (res.status === 100) {
            this.progressDetail = res.data ? res.data : [];
            this.dialogProgress = true;
          }
        });
    },
    showReturnVisit() {
      this.dialogRetuenVisit = true;
    },
    submitReturnVisit() {
      let data = {
        caseId: this.id,
        // content: this.returnForm.content,
        serviceQuality: this.returnForm.quality,
        serviceRate: this.returnForm.speed,
        taskStatus: 3,
        type: this.returnForm.type,
        dataObject: this.id
      };
      if (this.returnForm.content) {
        data.content = this.returnForm.content;
      }
      this.$axios.post(addCallbackInfoURL, data, this.header).then(res => {
        if (res.status === "100") {
          this.dialogRetuenVisit = false;
          this.$message({
            type: "success",
            message: "内容提交成功",
            center: true
          });
          this.$refs.form.getData({ orderId: this.id, orderType: 0 });
        }
      });
    },
    // 订单内容table begin
    handleContent(orderDetails) {
      orderDetails.forEach(item => {
        let expMarkInfo = "";
        if (
          item.expMark === "" &&
          item.expFirmCode === "" &&
          item.expS === ""
        ) {
          expMarkInfo = "";
        } else {
          expMarkInfo = `${item.expMark},${item.expFirmCode},${item.expS}`;
        }
        item.expMarkInfo = {
          VNode: this.$createElement("a", {
            domProps: {
              innerHTML: expMarkInfo
            },
            style: {
              textDecoration: "underline",
              cursor: "pointer"
            },
            on: {
              click: () => {
                this.showExpressDetail(item);
              }
            }
          })
        };
      });
      return orderDetails;
    },
    expChange(val) {
      if (val && val.expFirm) {
        this.express.expFirm = val.expFirm;
      }
    },
    showExpress(row, opera) {
      this.isExpressShow = true;
      this.express.orderId = this.orderId;
      this.express.orderDetailId = row.orderDetailId;
      if (opera === "edit") {
        this.express.expNum = row.expFirmNo;
        this.express.expCode = row.expFirmCode;
        this.express.orderType = 0;
      } else {
        this.express.expNum = "";
        this.express.expCode = "";
        this.$refs.expCode && this.$refs.expCode.clearValue();
        this.express.orderType = 1;
      }
    },
    addExpress() {
      this.$axios
        .post(
          addExpressInfoURL,
          this.$qs.stringify(this.express),
          this.postHeader
        )
        .then(res => {
          if (res.status === 100) {
            this.isExpressShow = false;
            this.getViewOrderDetailInfo();
          }
        });
    },
    async delExpress(row) {
      let isOk = await this.$confirm("是否删除快递信息");
      if (!isOk) {
        return false;
      }
      let data = {
        expFirm: "",
        expCode: "",
        expNo: "",
        id: row.orderDetailId,
        orderId: this.id
      };
      this.$axios.post(delExpURL, data, this.postHeader).then(res => {
        if (res.status === "100") {
          this.getViewOrderDetailInfo();
        }
      });
    },
    getViewOrderDetailInfo() {
      let params = {
        params: {
          orderId: this.id,
          orderType: 0
        }
      };
      this.$axios(getViewOrderDetailInfoURL, params).then(res => {
        if (res.status == 100) {
          // eslint-disable-line
          this.$set(this.content, "products", this.handleContent(res.data));
        }
      });
    },
    showExpressDetail(item) {
      let params = {
        params: {
          orderId: this.id,
          expCode: item.expFirmCode,
          expNum: item.expFirmNo
        }
      };
      this.$axios.get(getExpressDetailsURL, params).then(res => {
        if (res.status === 100) {
          this.expressInfoList = res.data.expContext;
          this.isExpressInfoShow = true;
        }
      });
    },
    // 订单内容table end
    // 判断订单是否可以加签的接口
    async querySecondCategory(subCode) {
      let params = {
        subCode: subCode
      };
      let { data } = await this.$axios.get(querySecondCategoryURL, {
        params: params
      });
      if (data) {
        this.secondCategoryList = data;
      }
    },
    // 查询是否能够加签
    async queryLabelConfig(subCode, communityId, orderNumber) {
      let { data } = await this.$axios.get(queryLabelConfigURL, {
        params: {
          subCode,
          communityId,
          orderNumber
        }
      });
      this.isCountersignType = data > 0;
    },
    // 查询二级分类
    async querySecondClassify() {
      let res = await this.$axios.get(queryServiceIDURL);
      let { data } = await this.$axios.get(querySignTypeURL, {
        params: { parentId: res.data }
      });
      this.secondCategoryOps = data.map(item => ({
        text: item.categoryName,
        value: item.id
      }));
      this.secondCategoryOps.unshift({
        text: "全部",
        value: undefined
      });
    },
    // 转派功能新增方法 start
    // 触底加载的方法
    load() {
      this.getDistributeList();
    },
    showRedeploy() {
      this.isRedeployShow = true;
      this.search();
    },
    // 工单上报
    async onReport () {
      await this.$axios.get(`${orderReportURL}?orderId=${this.id}`).then((res)=> {
        if (res.status === 100) {
          this.$message.success("工单数据已上报");
        } 
      });
    },
    // 转派人员
    redeploy() {
      if (this.radio === "") {
        this.$message("请选择转派人员");
        return;
      }
      let params = {
        orderId: this.id,
        dealUserId: this.radio.adminId,
        dealUserName: this.radio.adminName
      };
      let data = this.$qs.stringify(params);
      let orderStatus = Number(this.info.orderStatus);

      if(orderStatus === 1) {
        this.$axios.put(`${orderRedeployOther}?${data}`).then(res => {
            if (res.status === 100) {
              this.$message.success("派单成功");
              this.$router.go(-1);
            } else {
              this.$message(res.msg);
            }
        });
      } else if(orderStatus === 2 || orderStatus === 6) {
        this.$axios.put(`${orderRedeploy}?${data}`).then(res => {
            if (res.status === 100) {
              this.$message.success("派单成功");
              this.$router.go(-1);
            } else {
              this.$message(res.msg);
            }
        });
      }
    },
    search() {
      this.resData.hasMore = true;
      this.resData.curPage = 1;
      this.redeployList = [];
      this.getDistributeList();
    },
    getDistributeList() {
      if (this.resData.hasMore === false || this.resData.isResIng) {
        return;
      }
      let params = {
        orderId: this.id,
        curPage: this.resData.curPage++,
        pageSize: this.resData.pageSize,
        orderType: this.info.orderStatus,
        subCommunityId: this.$route.query.subCommunityId,
        userName: this.resData.userName

        // orderId: '000019e8-bb94-4b44-baa5-07c73fae8177',
        // curPage: 1,
        // pageSize: this.resData.pageSize,
        // orderType: 1,
        // subCommunityId: this.$route.query.subCommunityId,
        // userName: this.resData.userName,
        // storeId: '01d96323-f3a1-4a4f-9392-d4c63c0c4a60',
      };

      console.log(params);
      this.resData.isResIng = true;
      let data = this.$qs.stringify(params);
      this.$axios.get(`${getDistributeList}?${data}`).then(res => {
        this.resData.isResIng = false;
        if (res.status === 100) {
          this.resData.hasMore = res.data.pageResult.hasMore;
          for (let i = 0; i < res.data.resultList.length; i++) {
            this.redeployList.push(res.data.resultList[i]);
          }
        } else {
          if(res.msg) {
            this.$message(res.msg);
          } else {
            this.$message('网络连接错误！');
          }
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.newProgress {
  padding-left: 168px;
  span {
    margin-right: 30px;
  }
  a {
    cursor: pointer;
    color: #337ab7;
  }
}
.footerBtn {
  .v-control {
    margin-right: 15px;
  }
  .v-control:last-child {
    margin-right: 0;
  }
}
.progress {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-left: -23px;
  padding-left: 23px;
  .progress-top {
    display: flex;
    justify-content: space-between;
    line-height: 28px;
    border-bottom: 1px solid #ccc;

    .left {
      color: #1b8cf2;
    }
    .right {
    }
  }
  .progress-remark {
    margin: 15px 0;
  }
  .progress-view {
  }
}
.pro-group:last-child {
  .progress {
    border: none;
  }
}
.rates {
  text-align: left;
  margin-top: 10px;
}
.evaluate-panel {
  padding-left: 168px;
  margin-bottom: 10px;
  .title {
    margin-bottom: 10px;
    font-weight: 700;
  }
  .rate-row {
    display: flex;
    .col2 {
      display: flex;
      flex: 1;
      .rate-label {
        width: 70px;
      }
    }
  }
  .eva-cell {
    display: flex;
    margin: 5px 0;
    .label {
      width: 70px;
    }
    .text {
    }
  }
}

.redeployList {
  height: 500px;
  overflow-y: auto;
  font-size: 16px;
  line-height: 36px;
  li {
    line-height: 66px;
    text-align: left;
  }
  ::v-deep .el-radio__label {
    font-size: 18px;
    width: 200px;
    display: inline-block;
    overflow-x: auto;
    overflow-y: hidden;
    vertical-align: middle;
  }
  ::v-deep .el-radio__inner {
    width: 18px;
    height: 18px;
  }
  .number {
    margin-left: 18px;
    color: #ff4040;
  }
  .submit {
    margin: 0 auto;
  }
}
</style>
