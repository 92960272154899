// 获取列表
const getListURL = `${API_CONFIG.baseURL}orderAction!viewList.action?orderType=0&subHandle=0`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}order/work/export?subHandle=0`
// 获取组织列表
const getOrgListURL = `${API_CONFIG.butlerBaseURL}orgInfo/searchOrgByKeyword`
// 批量操作
const batchURL = `${API_CONFIG.butlerBaseURL}butler/operateOrder/batchOpreateOrder`
// 获取业主地址列表
const getUserRoomsURL = `${API_CONFIG.baseURL}serverCodewordAction!getUserRooms.action`
// 所属服务
const getViewSubURL = `${API_CONFIG.baseURL}communityStoreSubjectAction!viewSub.action`
// 录入人
const getAdminsURL = `${API_CONFIG.baseURL}serverCodewordAction!getAdmins.action`
// 获取支付方式
const getPayTypeListURL = `${API_CONFIG.controlBaseURL}accountChannel/getPayTypeList?type=1`
// 加签名称
const getOrderLabelNoPageListURL = `${API_CONFIG.butlerBaseURL}label/getLabelListNoPage`
const getOrderLabelListURL = `${API_CONFIG.butlerBaseURL}orderLabel/getOrderLabelList`
// 获取详情
const getDetailURL = `${API_CONFIG.baseURL}orderAction!view.action`
// 保存按钮
const saveURL = {
  reviceive: `${API_CONFIG.butlerBaseURL}butler/operateOrder/orderReceiving`, // 待接收
  dispose: `${API_CONFIG.butlerBaseURL}butler/operateOrder/orderDispose`, // 待处理
  finish: `${API_CONFIG.butlerBaseURL}butler/operateOrder/orderFinish`, // 处理中
  close: `${API_CONFIG.butlerBaseURL}butler/operateOrder/orderClose` // 关闭
}
// 回访接口
const addCallbackInfoURL = `${API_CONFIG.baseURL}orderAction!addcallbackInfo.action`
// 订单进度
const queryTaskListURL = `${API_CONFIG.butlerBaseURL}butler/operateOrder/queryTaskList`
// 上传接口
const uploadURL = `${API_CONFIG.uploadURL}?module=order`
// 订单内容相关
// 获取快递公司列表
const getExpInfoURL = `${API_CONFIG.baseURL}serverCommunityAction!getExpInfo.action`
// 增加快递信息
const addExpressInfoURL = `${API_CONFIG.shopBaseURL}butlerOrderExpress/addExpressInfo`
// 获取订单内容
const getViewOrderDetailInfoURL = `${API_CONFIG.shopBaseURL}orderAction!viewLogistics.action`
// 获取物流详情
const getExpressDetailsURL = `${API_CONFIG.shopBaseURL}butlerOrderExpress/getExpressDetails`
// 删除物流信息
const delExpURL = `${API_CONFIG.baseURL}orderAction!delExp.action`
// 处理中增加反馈
const addFeedbackInfoURL = `${API_CONFIG.baseURL}orderAction!addFeedbackInfo.action`
//
const querySecondCategoryURL = `${API_CONFIG.shopBaseURL}subjectCommonShu/getSubjectSubTypeBySubCode`
const querySignTypeURL = `${API_CONFIG.shopBaseURL}label/getCategoryByParentId`
// 获取标签配置信息
const queryLabelConfigURL = `${API_CONFIG.shopBaseURL}orderLabel/getOrderLabelListNoPage`
// 获取服务类别id
const queryServiceIDURL = `${API_CONFIG.shopBaseURL}labelCategory/getLabelCategoryServerId`
// 服务分类
const getServiceCategoryURL = `${API_CONFIG.butlerBaseURL}subjectCommonShu/getSubjectSubTypeBySubCode`
//转派人员列表
const getDistributeList = `${API_CONFIG.butlerBaseURL}operateOrder/getDistributeList`;
//转派
const orderRedeployOther = `${API_CONFIG.butlerBaseURL}operateOrder/orderRedeployOther`;
const orderRedeploy = `${API_CONFIG.butlerBaseURL}operateOrder/orderRedeploy`;
// 工单上报
const orderReportURL = `${API_CONFIG.butlerBaseURL}operateOrder/orderReport`;

export {
  getListURL,
  exportListURL,
  getOrgListURL,
  batchURL,
  getUserRoomsURL,
  getViewSubURL,
  getAdminsURL,
  getPayTypeListURL,
  getOrderLabelListURL,
  getOrderLabelNoPageListURL,
  getDetailURL,
  saveURL,
  addCallbackInfoURL,
  queryTaskListURL,
  uploadURL,
  getExpInfoURL,
  addExpressInfoURL,
  getViewOrderDetailInfoURL,
  getExpressDetailsURL,
  delExpURL,
  addFeedbackInfoURL,
  querySecondCategoryURL,
  querySignTypeURL,
  queryLabelConfigURL,
  queryServiceIDURL,
  getServiceCategoryURL,
  getDistributeList,
  orderRedeployOther,
  orderRedeploy,
  orderReportURL,
}
