var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "serviceSheetForm-container" },
    [
      _c(
        "form-panel",
        {
          ref: "form",
          attrs: { form: _vm.form, queryUrl: _vm.queryUrl },
          on: { update: _vm.update },
        },
        [
          _c(
            "col2-block",
            { attrs: { title: "订单流转" } },
            [
              _c("col2-progress", {
                attrs: { status: _vm.progressList, percent: _vm.progress },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "订单信息" } },
            [
              _c(
                "el-row",
                [
                  _c("col2-item", {
                    attrs: { label: "订单编号", text: _vm.orderCode },
                  }),
                  _vm.laborFeeShow
                    ? _c("col2-item", {
                        attrs: {
                          label: "是否房产",
                          text: `${
                            _vm.info.countersignType === 2 ? "是" : "否"
                          }`,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("col2-item", {
                attrs: { label: "订购服务", text: _vm.info.subName },
              }),
              _c("col2-item", {
                attrs: { label: "用户姓名", text: _vm.info.userName },
              }),
              _c("col2-item", {
                attrs: {
                  label: "工单来源",
                  text: `${
                    _vm.info.sourceType
                      ? _vm.sourceTypeMap[_vm.info.sourceType]
                      : ""
                  }${
                    _vm.info.serialNumberOutside
                      ? "(" + _vm.info.serialNumberOutside + ")"
                      : ""
                  }${
                    _vm.info.sourceType == 1 || _vm.info.sourceType == 2
                      ? "(" +
                        _vm.info.inuserName +
                        "  " +
                        _vm.info.inuserMobileNum +
                        ")"
                      : ""
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: { label: "联系电话", text: _vm.info.userPhone },
              }),
              _c("col2-item", {
                attrs: { label: "下单时间", text: _vm.info.referTime },
              }),
              _c("col2-item", {
                attrs: { label: "用户地址", text: _vm.info.userAddress },
              }),
              _c("col2-item", {
                attrs: {
                  label: "预约时间",
                  text: `${_vm.info.serverTime || "无"}`,
                },
              }),
              _c("col2-item", {
                attrs: { label: "服务商家", text: _vm.info.shopName },
              }),
              _c("col2-item", { attrs: { label: "订单状态" } }, [
                _c("span", { staticStyle: { color: "#a94442" } }, [
                  _vm._v(
                    _vm._s(_vm.orderStatusMap[_vm.info.orderStatus]) +
                      _vm._s(
                        _vm.info.orderStatus == "5"
                          ? `(${_vm.closeType[_vm.info.closeType]})`
                          : ""
                      ) +
                      _vm._s(
                        _vm.info.isAftersalesIng === 1 &&
                          _vm.info.orderStatus !== "5"
                          ? "(售后中)"
                          : ""
                      )
                  ),
                ]),
              ]),
              _c("col2-item", {
                attrs: {
                  label: "物流金额",
                  text: `${_vm.info.expensesMoney || "0.00"}元`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "支付方式",
                  text: `${_vm.payTypeMap[_vm.info.payType] || ""}${
                    _vm.info.payNo ? "(" + _vm.info.payNo + ")" : ""
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "订单类型",
                  text: `${
                    _vm.info.payCategory === 0
                      ? "个人订单"
                      : "组织订单(" + _vm.info.orgName + ")"
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "订单金额",
                  text: `${_vm.info.totalMoney || ""}元${
                    _vm.info.subName && _vm.info.subName.indexOf("维修") !== -1
                      ? "(人工费" +
                        _vm.info.laborFee +
                        ",材料费" +
                        _vm.info.materialFee +
                        ")"
                      : ""
                  }`,
                },
              }),
              _c("col2-item", {
                attrs: {
                  label: "支付状态",
                  text: `${_vm.info.payStatus === "0" ? "未支付" : "已支付"}`,
                },
              }),
              _c("col2-item", {
                attrs: { label: "支付时间", text: _vm.info.onlinePaidTime },
              }),
              _vm.info.payStatus !== "0"
                ? _c("col2-item", {
                    attrs: {
                      label: "应付金额",
                      text: `${_vm.info.orderMoney || ""}元`,
                    },
                  })
                : _vm._e(),
              _vm.info.payStatus !== "0"
                ? _c("col2-item", {
                    attrs: {
                      label: "实付金额",
                      text: `${_vm.info.realMoney || ""}元`,
                    },
                  })
                : _vm._e(),
              _c("col2-item", {
                attrs: {
                  label: "开票信息",
                  text: `${_vm.info.invoicStatus === 2 ? "已开票" : "未开票"}`,
                },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "收货人信息" } },
            [
              _c("col2-item", {
                attrs: { label: "收货人姓名", text: _vm.consignee.userName },
              }),
              _c("col2-item", {
                attrs: { label: "收货人电话", text: _vm.consignee.userPhone },
              }),
              _c("col2-item", {
                attrs: { label: "收货人地址", text: _vm.consignee.userAddress },
              }),
            ],
            1
          ),
          _c(
            "col2-block",
            { attrs: { title: "订单内容" } },
            [
              _vm.content.products && _vm.content.products.length > 0
                ? _c("table-panel", {
                    attrs: {
                      headers: _vm.orderHeaders,
                      tableData: _vm.content.products,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.canEdit
                          ? {
                              key: "operateSlot",
                              fn: function (scope) {
                                return [
                                  (_vm.info.orderStatus == 2 ||
                                    _vm.info.orderStatus == 6) &&
                                  _vm.info.deliveryType == 1
                                    ? _c(
                                        "div",
                                        [
                                          scope.row.expFirmCode
                                            ? _c(
                                                "div",
                                                [
                                                  _c("v-button", {
                                                    attrs: {
                                                      text: "编辑物流",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.showExpress(
                                                          scope.row,
                                                          "edit"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("v-button", {
                                                    attrs: {
                                                      text: "删除物流",
                                                      type: "text",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.delExpress(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _c("v-button", {
                                                attrs: {
                                                  text: "新增物流",
                                                  type: "text",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showExpress(
                                                      scope.row
                                                    )
                                                  },
                                                },
                                              }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  })
                : _vm._e(),
              _c(
                "col2-item",
                { attrs: { span: 24, label: "订单图片" } },
                [_c("v-preview", { attrs: { imgs: _vm.picturesList } })],
                1
              ),
              _c("col2-item", {
                attrs: {
                  span: 24,
                  label: "订单描述",
                  text: _vm.content.remark,
                },
              }),
            ],
            1
          ),
          _vm.newProgress.time
            ? _c("col2-block", { attrs: { title: "最新订单进度" } }, [
                _c("div", { staticClass: "newProgress" }, [
                  _c("span", [_vm._v(_vm._s(_vm.newProgress.time))]),
                  _c("span", [_vm._v(_vm._s(_vm.newProgress.user) + " ")]),
                  _c("span", [_vm._v(_vm._s(_vm.newProgress.remark))]),
                  _c("a", { on: { click: _vm.showProgressDetail } }, [
                    _vm._v(">>点击查看详情"),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "col2-block",
            { attrs: { title: "处理情况" } },
            [
              _c("div"),
              _vm.info.orderStatus == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "处理后图片" } },
                    [
                      _c("v-uploader", {
                        ref: "myUpload",
                        attrs: {
                          action: _vm.uploadUrl,
                          imgUrls: _vm.form.imageUrls,
                          limit: 4,
                        },
                        on: {
                          "update:imgUrls": function ($event) {
                            return _vm.$set(_vm.form, "imageUrls", $event)
                          },
                          "update:img-urls": function ($event) {
                            return _vm.$set(_vm.form, "imageUrls", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.afterHanderPic
                ? _c(
                    "el-form-item",
                    { attrs: { label: "处理后图片" } },
                    [
                      _c("v-preview", {
                        attrs: { imgs: _vm.afterPicturesList },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.countersignTypeVisible
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "工单加签",
                        prop: "countersignTypeList",
                        rules: [
                          {
                            required: Number(_vm.info.orderStatus) === 2,
                            message: "请选择标签",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("chosenListPanel", {
                        attrs: { list: _vm.form.countersignTypeList },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(
                              _vm.form,
                              "countersignTypeList",
                              $event
                            )
                          },
                          select: _vm.toSelectCountersignType,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.operationShow
                ? _c(
                    "el-form-item",
                    { attrs: { label: "处理反馈" } },
                    [
                      _c("v-textarea", {
                        attrs: { maxlength: 300 },
                        model: {
                          value: _vm.form.operationDes,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "operationDes", $$v)
                          },
                          expression: "form.operationDes",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.info.orderStatus == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "处理状态" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.operaOps },
                        model: {
                          value: _vm.form.operaType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "operaType", $$v)
                          },
                          expression: "form.operaType",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.operaType !== "close"
                ? _c(
                    "div",
                    [
                      _vm.laborFeeShow
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "人工费",
                                prop: "laborFee",
                                rules: [
                                  {
                                    required:
                                      !_vm.form.materialFee &&
                                      _vm.form.materialFee !== 0,
                                    message: "请输入人工费",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: { precision: 2 },
                                model: {
                                  value: _vm.form.laborFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "laborFee", $$v)
                                  },
                                  expression: "form.laborFee",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.laborFeeShow
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "材料费",
                                prop: "materialFee",
                                rules: [
                                  {
                                    required:
                                      !_vm.form.laborFee &&
                                      _vm.form.laborFee !== 0,
                                    message: "请输入材料费",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _c("v-input-number", {
                                attrs: { precision: 2 },
                                model: {
                                  value: _vm.form.materialFee,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "materialFee", $$v)
                                  },
                                  expression: "form.materialFee",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.couponValueShow
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "抵价票券" } },
                            [
                              _c("v-input-number", {
                                model: {
                                  value: _vm.form.couponNum,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "couponNum", $$v)
                                  },
                                  expression: "form.couponNum",
                                },
                              }),
                              _vm._v("  张, 总抵扣  \n          "),
                              _c("v-input-number", {
                                attrs: { precision: 2 },
                                model: {
                                  value: _vm.form.couponValue,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "couponValue", $$v)
                                  },
                                  expression: "form.couponValue",
                                },
                              }),
                              _vm._v("  元\n        "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.couponValueShow
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "实收金额",
                                rules: [
                                  {
                                    required: true,
                                    message: "请输入实收金额",
                                    trigger: "blur",
                                  },
                                ],
                              },
                            },
                            [
                              _vm.laborFeeShow
                                ? _c("v-input-number", {
                                    attrs: {
                                      precision: 2,
                                      disabled: "",
                                      value: _vm.cash,
                                    },
                                  })
                                : _c("v-input-number", {
                                    attrs: { precision: 2 },
                                    model: {
                                      value: _vm.form.cash,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "cash", $$v)
                                      },
                                      expression: "form.cash",
                                    },
                                  }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.evaluateShow && _vm.evaluateList[0]
            ? _c(
                "col2-block",
                { attrs: { title: "用户评价" } },
                _vm._l(_vm.evaluateList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "evaluate-panel" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("用户评价#" + _vm._s(index + 1)),
                      ]),
                      _c("div", { staticClass: "rate-row" }, [
                        _c(
                          "div",
                          { staticClass: "col2" },
                          [
                            _c("span", { staticClass: "rate-label" }, [
                              _vm._v("服务质量"),
                            ]),
                            _c("rate", {
                              staticClass: "rate",
                              attrs: {
                                value: Number(item.quality),
                                disabled: "",
                                "text-color": "#ff9900",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col2" },
                          [
                            _c("span", { staticClass: "rate-label" }, [
                              _vm._v("服务速度"),
                            ]),
                            _c("rate", {
                              staticClass: "rate",
                              attrs: {
                                value: Number(item.speed),
                                disabled: "",
                                "text-color": "#ff9900",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      item.content
                        ? _c("div", { staticClass: "eva-cell" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("评价内容:"),
                            ]),
                            _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.content)),
                            ]),
                          ])
                        : _vm._e(),
                      item.appraiseTime
                        ? _c("div", { staticClass: "eva-cell" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("评价时间:"),
                            ]),
                            _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.appraiseTime)),
                            ]),
                          ])
                        : _vm._e(),
                      item.replyTime
                        ? _c("div", { staticClass: "eva-cell" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("回复时间:"),
                            ]),
                            _c("span", { staticClass: "text" }, [
                              _vm._v(_vm._s(item.replyTime)),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.canEdit
            ? _c(
                "div",
                {
                  staticClass: "footerBtn",
                  attrs: { slot: "footerSlot" },
                  slot: "footerSlot",
                },
                [
                  _c("v-button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.submitTextBack,
                        expression: "submitTextBack",
                      },
                    ],
                    attrs: { type: "success", text: _vm.submitTextBack },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm(0)
                      },
                    },
                  }),
                  _c("v-button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.submitTextNext,
                        expression: "submitTextNext",
                      },
                    ],
                    attrs: { type: "default", text: _vm.submitTextNext },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm(1)
                      },
                    },
                  }),
                  _vm.info.orderStatus === "2"
                    ? _c("v-button", {
                        attrs: { text: "工单上报", permission: "report" },
                        on: { click: _vm.onReport },
                      })
                    : _vm._e(),
                  _vm.redeployShow
                    ? _c("v-button", {
                        attrs: { permission: "transfer", text: "转派" },
                        on: { click: _vm.showRedeploy },
                      })
                    : _vm._e(),
                  _vm.returnVisitShow
                    ? _c("v-button", {
                        attrs: { type: "success", text: "进行回访" },
                        on: { click: _vm.showReturnVisit },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          isShow: _vm.isShow,
          searchUrl: _vm.getCountersignListUrl,
          headers: _vm.countersignTableHeader,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          responseKey: _vm.responseKey,
          backFill: _vm.form.countersignTypeList,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.form, "countersignTypeList", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.form, "countersignTypeList", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "标签名称" },
                  model: {
                    value: _vm.searchParams.labelName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "labelName", $$v)
                    },
                    expression: "searchParams.labelName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "二级分类", options: _vm.secondCategoryOps },
                  model: {
                    value: _vm.searchParams.categoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "categoryId", $$v)
                    },
                    expression: "searchParams.categoryId",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单进度",
            visible: _vm.dialogProgress,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogProgress = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-timeline",
                _vm._l(_vm.progressDetail, function (item, index) {
                  return _c(
                    "el-timeline-item",
                    { key: index, staticClass: "pro-group" },
                    [
                      _c("div", { staticClass: "progress" }, [
                        _c("div", { staticClass: "progress-top" }, [
                          _c("span", { staticClass: "left" }, [
                            _vm._v(_vm._s(item.track)),
                          ]),
                          _c("span", { staticClass: "right" }, [
                            _vm._v(_vm._s(item.time)),
                          ]),
                        ]),
                        _c("div", { staticClass: "progress-remark" }, [
                          _vm._v(_vm._s(item.remark)),
                        ]),
                        item.images.length > 0
                          ? _c(
                              "div",
                              { staticClass: "progress-view" },
                              [
                                _c("v-preview", {
                                  attrs: {
                                    imgs: item.images,
                                    imgStyle: _vm.previewStyle,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加回访记录",
            visible: _vm.dialogRetuenVisit,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRetuenVisit = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { "label-width": "120px", "label-suffix": "：" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "方式" } },
                    [
                      _c("checkbox-plus", {
                        ref: "returnType",
                        attrs: { options: _vm.returnType },
                        model: {
                          value: _vm.returnForm.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnForm, "type", $$v)
                          },
                          expression: "returnForm.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务质量" } },
                    [
                      _c("rate", {
                        staticClass: "rates",
                        attrs: { "text-color": "#ff9900" },
                        model: {
                          value: _vm.returnForm.quality,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnForm, "quality", $$v)
                          },
                          expression: "returnForm.quality",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务速度" } },
                    [
                      _c("rate", {
                        staticClass: "rates",
                        attrs: { "text-color": "#ff9900" },
                        model: {
                          value: _vm.returnForm.speed,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnForm, "speed", $$v)
                          },
                          expression: "returnForm.speed",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0" } },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder: "请填写回访结果",
                          maxlength: 100,
                        },
                        model: {
                          value: _vm.returnForm.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnForm, "content", $$v)
                          },
                          expression: "returnForm.content",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                attrs: { text: "提 交" },
                on: { click: _vm.submitReturnVisit },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "物流信息",
            width: "400px",
            visible: _vm.isExpressShow,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isExpressShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "140px", "label-suffix": "：" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "快递单号" } },
                [
                  _c("v-input", {
                    model: {
                      value: _vm.express.expNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.express, "expNum", $$v)
                      },
                      expression: "express.expNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "快递公司" } },
                [
                  _c(
                    "v-select2",
                    _vm._b(
                      {
                        ref: "expCode",
                        on: { onChange: _vm.expChange },
                        model: {
                          value: _vm.express.expCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.express, "expCode", $$v)
                          },
                          expression: "express.expCode",
                        },
                      },
                      "v-select2",
                      _vm.expCodeParams,
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                attrs: { type: "default", text: "取 消" },
                on: {
                  click: function ($event) {
                    _vm.isExpressShow = false
                  },
                },
              }),
              _c("v-button", {
                attrs: { text: "确 定" },
                on: { click: _vm.addExpress },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: "物流详情",
            width: "600px",
            visible: _vm.isExpressInfoShow,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isExpressInfoShow = $event
            },
          },
        },
        [
          _vm._l(_vm.expressInfoList, function (item, index) {
            return _c("div", { key: index, staticClass: "expBox" }, [
              _c("span", [_vm._v(_vm._s(item.ftime))]),
              _c("span", [_vm._v(_vm._s(item.context))]),
            ])
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("v-button", {
                attrs: { type: "default", text: "关 闭" },
                on: {
                  click: function ($event) {
                    _vm.isExpressInfoShow = false
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: { title: "转派", width: "600px", visible: _vm.isRedeployShow },
          on: {
            "update:visible": function ($event) {
              _vm.isRedeployShow = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入关键字" },
                  model: {
                    value: _vm.resData.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.resData, "userName", $$v)
                    },
                    expression: "resData.userName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.search },
                    slot: "append",
                  }),
                ],
                1
              ),
              _c(
                "ul",
                {
                  directives: [
                    {
                      name: "infinite-scroll",
                      rawName: "v-infinite-scroll",
                      value: _vm.load,
                      expression: "load",
                    },
                  ],
                  staticClass: "redeployList",
                },
                _vm._l(_vm.redeployList, function (item, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: item },
                          model: {
                            value: _vm.radio,
                            callback: function ($$v) {
                              _vm.radio = $$v
                            },
                            expression: "radio",
                          },
                        },
                        [_vm._v(_vm._s(item.adminName))]
                      ),
                      _c("span", { staticClass: "number" }, [
                        _vm._v(_vm._s(item.taskNum)),
                      ]),
                      _c("span", [_vm._v("个工单在处理")]),
                    ],
                    1
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "submit" },
                [
                  _c("v-button", {
                    attrs: { text: "确认分配" },
                    on: { click: _vm.redeploy },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }